import { ChangeDetectorRef, Component, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../order.service';
import { Client, OrderResponse, ReviewFinding } from '../orders.interface';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, forkJoin, map, startWith } from 'rxjs';
import { ActionPermissions, config } from 'src/app/shared/constants';
import { ModalTitle, OrderStatuses, USPSVerification } from 'src/app/shared/enums';
import { UserConfigurationService } from 'src/app/core/services/user-configuration.service';
import { OrderCompletedModalComponent } from './order-completed-modal/order-completed-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { message } from 'src/app/shared/constants/alerts_messages';
import { ToastrService } from 'ngx-toastr';
import { generalErrorMessage } from 'src/app/shared/errors/error-messages';
import { OrderReviewerAssignmentComponent } from './order-reviewer-assignment/order-reviewer-assignment.component';
import { FormControl } from '@angular/forms';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-order-review',
  templateUrl: './order-review.component.html',
  styleUrls: ['./order-review.component.scss']
})
export class OrderReviewComponent {
  formHtml: SafeHtml;
  myHtml: string;
  orderDetail: OrderResponse;
  clientDetail: Client;
  clientOnReportDetail: Client;
  totalfindings = 0;
  resolvedfindings = 0;
  orderId = '';
  token: string;
  orderNo: string;
  source: string;
  revisionNumber: number;
  reviewId: string;
  reviewVersionId: string;
  orderStatus: string;
  findingsData: ReviewFinding[];
  originalFindingsData: ReviewFinding[];
  orderReviewHeaderData = {
    client: '',
    clientAddress: '',
    clientOnReport: '',
    clientOnReportAddress: '',
    borrowerName: '',
    loanType: '',
    fhaCaseNo: '' as string | null,
    purposeType: '',
    isEADactive: false,
    isUCDPactive: false,
    reviewer: '' as string | null,
    appraiser: '' as string | null,
    createdDate: '',
    dueDate: '' as string | null,
    revisionNo: 0,
    address: '',
    vendor: '' as string | null,
    product: '' as string | null,
  };
  addressVerified: string;
  containerElement: HTMLElement;
  pdfUrl = '';
  rejectedfindings: number;
  toggleState = true;
  orderStatuses = OrderStatuses;
  isPreview = false;
  orderHtmlList: any = [];
  findingsTabSelection = 'unResolved';
  selectedForm: any;
  selectedFormImages: any;
  data: {
    orderNumber: any,
    revisionNumber: any,
    source: any,
    token: any,
    review_id: any
  }

  permission = ActionPermissions;
  filteredFindings: ReviewFinding[]= [];
  xpathToScroll: any;
  orderReviewForms: any;
  getFindingForm: any;
  receivedFocus: ReviewFinding | null;
  expendInfo = false;
  oldOrders = false;
  isActionPerformed = false;
  documents: any;
  currentUserId: string;
  currentUserRole: string;
  isRightSidePreference = false;
  orderHistory: any;
  enableFindingClick = false;
  eadAppraisalNumber: string;
  ucdpAppraisalNumber: string;
  revisionRequested = false;
  selectedTab: any = 0;
  draggedFindings: any;
  revisionList: any[] = [];
  selectedRevision: any;
  revisionSendToClient = false;
  stepForRevision = 0;
  isEADsubmitted = false;
  isUCDPsubmitted = false;
  vendorAndReviewer: any;
  sortRevisionArray: any[] = [];
  countData = {
    'Approved': 0,
    'Rejected': 0,
    'Not Applicable': 0
  };

  ModalTitle = ModalTitle;

  nonce: any = '';

  reviewerList: any = [];
  filteredReviewers: any[] = [];
  reviewerCtrl = new FormControl();
  filteredAdmins: any[] = [];
  adminList: any = [];
  filteredManagers: any[] = [];
  managerList: any = [];
  assignedUsersList: any[] = [];

  isDropdownOpen: boolean = false;
  imageCache: { [key: string]: string } = {};
  showInitials = false;

  expendSidebar = false;
  lastSelectedFinding: any;

  constructor(
    private route: ActivatedRoute,
    public cdRef: ChangeDetectorRef,
    private orderService: OrderService,
    public elementRef: ElementRef,
    private sanitizer: DomSanitizer,
    public userConfig: UserConfigurationService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    public sharedService: SharedService,
    private router: Router
  ) {
    this.nonce = (
      document.querySelector('meta[name="custom_nonce"]') as HTMLMetaElement
    )?.content;
    this.getConfigurationsFromLS();
  }

  ngOnInit(): void {
    this.getReviewers();
    this.selectedForm = null
    this.route.params.subscribe(params => {
      this.orderId = params['orderId'];
      this.token = params['token'];
      this.getOrderDetails(true, false);
      this.getOrderHistory()
      this.getRevisionTypes()
    });

    this.route.queryParams.subscribe(params => {
      const reviewId = params['reviewId'];
      this.reviewId = reviewId;
      this.getOrderHtml(reviewId);
    });
      this.orderService.getFocus().subscribe((data: ReviewFinding | null) => {
      this.receivedFocus = data;
      this.htmlMapping();
    });
    this.selectedTab = this.getParameterByName('tab');
  }


  getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

  ngAfterViewInit(): void {
    this.containerElement = document.getElementById('htmlContainer') as HTMLElement;
  }

  getOrderHistory(): void {
    this.orderService.getOrderHistory(this.orderId, this.token).subscribe((response) => {
      this.orderHistory = response;
    })
  }

  getConfigurationsFromLS(): void {
    const encodedConfigurations = localStorage.getItem('configurations');
    if (encodedConfigurations) {
      const decodedConfigurations = JSON.parse(atob(encodedConfigurations));
      this.currentUserRole = decodedConfigurations.role[0];
      if (decodedConfigurations.user) {
        this.currentUserId = decodedConfigurations.user.id;
        this.isRightSidePreference = decodedConfigurations.user.is_review_layout_right;
      }
    } else {
      this.isRightSidePreference = false;
    }
  }


  togglePdfDisplayState(): void {
    this.toggleState = !this.toggleState;
  }

  htmlMapping() {
    if (this.receivedFocus) {
      this.cdRef.detectChanges();
      const customAttrValue = this.receivedFocus.xpath;
      if (customAttrValue && this.orderStatus !== OrderStatuses.COMPLETED && this.orderStatus !== OrderStatuses.REVISION_REQUESTED) {
        let inputElement = this.elementRef.nativeElement.querySelector(`[data-xpath="${customAttrValue}"]`);
        if (!inputElement) {
          inputElement = this.elementRef.nativeElement.querySelector(`[data-image-id="${customAttrValue}"]`);
        }

        // Clicking on finding if it is CVP finding, selecting form from review_form_id and scrolling enables for images
        if (this.receivedFocus?.review_form_id && this.orderReviewForms?.length > 0) {
          this.getFindingForm = this.orderReviewForms.filter((form: any) => {return form.id === this.receivedFocus!.review_form_id})
          this.selectFormOnFindingClick()
          this.getFindingsForHighlight(inputElement, this.receivedFocus, customAttrValue, true, true)
        } else if (this.orderHtmlList?.length > 0) {
          const filteredHtml = this.orderHtmlList.filter((item: any) => item.html_string.includes(customAttrValue));
          this.getFindingForm = this.orderReviewForms.filter((form: any) => form.name === filteredHtml[0]?.form_name);
          if (filteredHtml.length > 0) {
            this.selectFormOnFindingClick()
            setTimeout(() => {
              let inputElement = this.elementRef.nativeElement.querySelector(`[data-xpath="${customAttrValue}"]`);
              this.getFindingsForHighlight(inputElement, this.receivedFocus, customAttrValue, true, true)
            }, 2000);
          }
        }
      }
    }
  }

  selectFormOnFindingClick() {
    if (this.getFindingForm && this.getFindingForm.length > 0) {
      if (this.selectedForm && this.selectedForm.id === this.getFindingForm[0].id) {
        return;
      }
      this.onFormChange(this.getFindingForm[0], this.receivedFocus, true)
    }
  }

  getFindingsForHighlight (inputElement: any, restrictedWord: any, xpath:any, isScrolling: boolean, findingClicked: boolean) {
    if (restrictedWord.review_finding_type.name == "RW") {
      let regex = new RegExp(restrictedWord.review_finding_type.name, "g");
      let regexNewLine = new RegExp("\n", "g");
      const wordToHighlight = restrictedWord.finding_text2.replace(regex, "").trim();
      const text = (inputElement.textContent).replace(regex, "").replace(regexNewLine, "").trim();
      inputElement.innerHTML = this.highlightWord(wordToHighlight!, text, xpath, restrictedWord, findingClicked, inputElement)
      inputElement.style.whiteSpace = 'normal';

      if (isScrolling && findingClicked) {
        this.findingTransition(xpath)
        this.orderService.scrollToFinding(inputElement, this.containerElement);
      }
    } else if (inputElement && inputElement instanceof HTMLInputElement) {
      if (inputElement.type == "checkbox") {
        const spanElement = inputElement.parentElement;
        const newHtml = `<span data-scroll-xpath="${xpath}" class="form-chip">${restrictedWord.review_finding_type.name}</span>`;
        if (!spanElement?.innerHTML.includes(newHtml)) {
          spanElement?.insertAdjacentHTML('beforeend', newHtml);
          spanElement!.classList.add("highlight-finding");
        }
        if (isScrolling && findingClicked) {
          this.findingTransition(xpath)
          this.orderService.scrollToFinding(spanElement!, this.containerElement);
        }
      } else if (inputElement.type == "radio") {
        const elements = this.elementRef.nativeElement.querySelectorAll(`[data-xpath="${xpath}"]`);
        elements.forEach((element: HTMLElement) => {
          const spanElement = element.parentElement;
          const newHtml = `<span data-scroll-xpath="${xpath}" class="form-chip">${restrictedWord.review_finding_type.name}</span>`;
          if (!spanElement?.innerHTML.includes(newHtml)) {
            spanElement?.insertAdjacentHTML('beforeend', newHtml);
          }
          spanElement!.classList.add("highlight-finding");
          if (isScrolling && findingClicked) {
            this.findingTransition(xpath)
            inputElement.focus();
          }
        });
      }
    } else if (inputElement && inputElement.tagName == "SPAN" && !xpath.includes('PHOTO_FILE.')) {
      const regex = new RegExp(restrictedWord.review_finding_type.name, "g");
      const regexNewLine = new RegExp("\n", "g");
      const wordToHighlight = inputElement.textContent.replace(regex, "").replace(regexNewLine, "").trim();
      inputElement.innerHTML = this.highlightWord(wordToHighlight, wordToHighlight, xpath, restrictedWord, findingClicked, false);
      inputElement.parentElement.style.whiteSpace = 'normal';
      const findingXpath = inputElement.attributes.getNamedItem("data-xpath")?.value;
      if (isScrolling && findingClicked && findingXpath && findingXpath === xpath)
        this.orderService.scrollToFinding(inputElement, this.containerElement);
    } else if (xpath.includes('PHOTO_FILE.')) {
      const newHtml = `<span data-scroll-xpath="${xpath}" class="form-chip">${restrictedWord.review_finding_type.name}</span>`;
      if (!inputElement?.innerHTML.includes(newHtml) && findingClicked) {
        inputElement?.insertAdjacentHTML('beforeend', newHtml);
      }
      if (isScrolling && findingClicked) {
        this.orderService.scrollToFinding(inputElement, this.containerElement);
        const spanElement = inputElement.querySelector(`[data-scroll-xpath="${xpath}"]`);
        spanElement.style.display = 'block'
        if(inputElement.parentElement.classList.contains('box')) {
          inputElement.parentElement.style.overflow = 'visible';
        }
      }
    }
  }

  escapeRegExp(word: any) {
    return word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  highlightWord(wordToHighlight: string, text: string, xpath: string, restrictedWord: any, findingClicked: boolean, inputElement: any) {
    const escapedWordToHighlight = this.escapeRegExp(wordToHighlight);
    const highlightedText = text.replace(
      new RegExp(`(${escapedWordToHighlight})`, 'gi'),
      `<span data-scroll-xpath="${xpath}" class="form-chip">${restrictedWord.review_finding_type.name}</span>
        <span data-document-xpath="${xpath}" class="highlight-finding">$1</span>`
    );

    if (inputElement) {
      inputElement.style.backgroundColor = "#BBE2EA";
      inputElement.style.cursor = "pointer";
    }

    if (findingClicked) {
      this.findingTransition(xpath)
    }

    return highlightedText
  }

  findingTransition(xpath: string) {
    const highlightFindingElement: any = document.querySelector(`[data-xpath="${xpath}"]`) as HTMLElement;
    if (highlightFindingElement) {
      highlightFindingElement.style.fontWeight = 'bold';
      highlightFindingElement.style.fontSize = '15px';
      highlightFindingElement.style.transition = '0.3s ease-in-out';
      setTimeout(() => {
        highlightFindingElement.style.fontWeight = 'unset';
        highlightFindingElement.style.fontSize = 'unset';
        highlightFindingElement.style.transition = '0.3s ease-in-out';
      }, 500);
    }
  }


  @HostListener('click', ['$event.target']) onClick(e: any) {
    const attrMap = e.attributes;
    const xpath = attrMap.getNamedItem("data-document-xpath")?.value;
    const xpathRW = attrMap.getNamedItem("data-xpath")?.value;
    const xpathToScroll = attrMap.getNamedItem("data-scroll-xpath")?.value;
    if (xpath) {
      const inputElement = this.elementRef.nativeElement.querySelector(`[data-scroll-xpath="${xpath}"]`);
      if (inputElement) {
        const spanElement = inputElement.parentElement;
        if(spanElement.classList.contains('single-line-input') || spanElement.textContent.includes('NAN_STANDARD')) {
          this.chipOverflowDesign(spanElement);
        }
        this.chipDisplayDesign(inputElement)
        const spanElementRw = spanElement.parentElement;
        if (spanElementRw) {
          this.chipOverflowDesign(spanElementRw);
        }
      }
    } else if (e.classList.contains('highlight-finding')) {
      const relatedSpan = e.querySelector('.form-chip');
      if (relatedSpan) {
        this.chipDisplayDesign(relatedSpan)

      }
    } else if (e.tagName.toLowerCase() === 'img') {
      const spanElement = e.parentElement;
      const imageXpath = spanElement.attributes.getNamedItem("data-image-id")?.value;
      const inputElement = spanElement.querySelector(`[data-scroll-xpath="${imageXpath}"]`);
      this.chipDisplayDesign(inputElement);
      if(spanElement.classList.contains('box')) {
        this.chipOverflowDesign(spanElement);
      }
    } else if (xpathToScroll){
      this.xpathToScroll = xpathToScroll
    }

    if (xpathRW) {
      const inputElement = this.elementRef.nativeElement.querySelector(`[data-scroll-xpath="${xpathRW}"]`);
      if (inputElement && inputElement.textContent === 'RW') {
        const spanElement = this.elementRef.nativeElement.querySelector(`[data-xpath="${xpathRW}"]`);
        this.chipDisplayDesign(inputElement);
        if (spanElement) {
          this.chipOverflowDesign(spanElement.parentElement);
          this.chipOverflowDesign(spanElement);
        }

      }
    }
  }

  chipOverflowDesign(spanElement: any) {
    const computedStyle = window.getComputedStyle(spanElement);
    const displayPropertyValue = computedStyle.getPropertyValue('overflow');
    const overflowHidden = spanElement.parentElement.style.overflow != '' ? 'hidden' : '';
    spanElement.style.overflow = displayPropertyValue === 'hidden' ? 'visible' : overflowHidden;
    spanElement.style.zIndex = '3'
  }

  chipDisplayDesign(inputElement: any) {
    const computedStyle = window.getComputedStyle(inputElement);
    const displayPropertyValue = computedStyle.getPropertyValue('display');
    inputElement.style.display = displayPropertyValue === 'none' ? 'block' : 'none'
  }

  getOrderHtml(reviewId: string) {
    this.orderService.getOrderHtml(reviewId, this.token).subscribe(
      (orderHtml: any) => {
        this.orderHtmlList = orderHtml;
        this.oldOrders = this.orderHtmlList && this.orderHtmlList?.length === 1 && this.orderHtmlList[0]?.form_name === null;
        if (this.oldOrders) {
          const html = this.orderHtmlList[0];
          this.myHtml = html ? html['html_string'] : null;

          this.myHtml = this.myHtml
          .replace(/(<style)/g, `$1 nonce="${this.nonce}"`)
          .replace(/(<script)/g, `$1 nonce="${this.nonce}"`)
          .replace(/(<object)/g, `$1 nonce="${this.nonce}"`)
          .replace(/(style="[^"]*")/g, `$1 nonce="${this.nonce}"`);


          // NOSONAR: bypassing Angular's html sanitization
          this.formHtml = this.sanitizer.bypassSecurityTrustHtml(this.myHtml);

          setTimeout(() => {
            this.setNoncesOnNewSvg();
          }, 2000);

          if (this.formHtml) {
            this.toggleState = false;
          }
        }
        this.enableFindingClick = true;
      });
  }

  getHtml(changedForm: any, findingClicked = false) {
    if (changedForm) {
      this.selectedForm = changedForm;
      let html: any;
      if (this.oldOrders) {
        html = this.orderHtmlList[0];
      } else {
        html = this.orderHtmlList.find((orderHtml: any) => orderHtml.form_name === changedForm.name);
      }      this.myHtml = html ? html['html_string'] : null;
      if (this.myHtml) {
        this.myHtml = this.tempProcessing(this.myHtml);

        this.myHtml = this.myHtml
        .replace(/(<style)/g, `$1 nonce="${this.nonce}"`)
        .replace(/(<script)/g, `$1 nonce="${this.nonce}"`)
        .replace(/(<object)/g, `$1 nonce="${this.nonce}"`)
        .replace(/(style="[^"]*")/g, `$1 nonce="${this.nonce}"`);

        // NOSONAR: bypassing Angular's html sanitization
        this.formHtml = this.sanitizer.bypassSecurityTrustHtml(this.myHtml);
        setTimeout(() => {
          this.setNoncesOnNewSvg();
        }, 2000);


        this.getInputElement(findingClicked);
      }
      if (this.formHtml) {
        this.toggleState = false;
      }
    }
  }

  setNoncesOnNewSvg() {
    const svgContainer = document.getElementById('htmlContainer');
    const svgObject = svgContainer?.querySelector('object');

    if (svgObject) {
      // Access the contentDocument of the loaded SVG
      const svgDoc = svgObject.contentDocument;

      if (svgDoc) {
        // Query for the <defs> tag and then for the <style> tag inside the defs
        const styleTag = svgDoc.querySelector('defs style');

        if (styleTag) {
          // Set the nonce attribute on the <style> tag
          styleTag.setAttribute('nonce', this.nonce);
        }
      }
    }
  }

  getInputElement(findingClicked: boolean) {
    if (this.orderDetail?.reviews[0]?.review_findings) {
      setTimeout(() => {
        this.orderDetail?.reviews[0]?.review_findings.forEach(element => {
          if (element.xpath) {
            const inputElement = this.elementRef.nativeElement.querySelector(`[data-xpath="${element.xpath}"]`);
            this.getFindingsForHighlight(inputElement, element, element.xpath, false, false)
          }
        });
        if (this.receivedFocus) {
          const inputElement = this.elementRef.nativeElement.querySelector(`[data-xpath="${this.receivedFocus.xpath}"]`);
          if (inputElement) {
            this.getFindingsForHighlight(inputElement, this.receivedFocus, this.receivedFocus.xpath, findingClicked, findingClicked)
          }
        }
      }, 3000);
    }
  }

  tempProcessing(html: string) {
    const pattern = `#idrviewer { transition-timing-function: ease; transition-duration: 200ms; top: 0; bottom: 0; left: 0; right: 0; position: absolute; }`;
    const replacement = `#idrviewer { transition-timing-function: ease; transition-duration: 200ms; top: 0; bottom: 0; left: 0; right: 0;}`;
    const processedHtml = html.replace(pattern, replacement);
    return processedHtml;
  }

  getOrderDetails(loadDocument: boolean, isAction?: boolean): void {
    this.orderService.getOrderDetail(this.orderId, this.token).subscribe(
      (orderDetail) => {
        this.orderDetail = orderDetail;
        this.reviewerCtrl.setValue(orderDetail?.reviews[0]?.assigned_to_reviewer?.user);
        this.filterOptions(orderDetail?.reviews[0]?.assigned_to_reviewer?.user);
        this.showInitials = orderDetail?.reviews[0]?.assigned_to_reviewer?.user?.public_image ? false : true;
        this.clientDetail = orderDetail.client;
        this.clientOnReportDetail = orderDetail.client_on_report;
        this.orderNo = orderDetail?.order_number;
        this.source = orderDetail?.source;
        this.orderStatus = orderDetail?.order_status?.status;
        this.isPreviewMode(orderDetail);
        this.reviewId = orderDetail?.reviews[0]?.id;
        this.revisionNumber = orderDetail?.reviews[0]?.review_version_number;
        if (loadDocument) {
          this.getPDFdocument(orderDetail);
        }
        this.getFilteredFindings(this.findingsTabSelection, orderDetail?.reviews[0]?.review_findings, isAction);
        this.getOrderReviewPDFHeaderData(orderDetail);
        this.isEADsubmitted = orderDetail?.reviews[0]?.is_ead_submitted;
        this.isUCDPsubmitted = orderDetail?.reviews[0]?.is_ucdp_submitted;
        this.data = {
          orderNumber: this.orderNo,
          revisionNumber: this.revisionNumber,
          source: this.source,
          token: this.token,
          review_id: this.reviewId
        }
        this.eadAppraisalNumber = orderDetail.ead_appraisal_no;
        this.ucdpAppraisalNumber = orderDetail.ucdp_appraisal_no;
        this.vendorAndReviewer = this.orderDetail?.reviews[0];
        this.originalFindingsData = this.orderDetail?.reviews[0]?.review_findings;
        this.extractFindingsCount(orderDetail);
        });

      if (isAction) {
        this.getOrderHistory();
      }
  }

  isPreviewMode(orderDetail: OrderResponse) {
    if ((this.orderStatus === OrderStatuses.COMPLETED ||
      this.orderStatus === OrderStatuses.REVISION_REQUESTED ||
      this.orderStatus === OrderStatuses.ARCHIVED) || (this.orderStatus === OrderStatuses.PENDING_ESCALATED_REVIEW && orderDetail.reviews[0].assigned_to_reviewer?.user?.id === this.currentUserId) ||
      (!this.userConfig.checkPermissions(this.permission.ORDER_REVIEW_CREATE) && orderDetail.reviews[0].assigned_to_reviewer
        && orderDetail.reviews[0].assigned_to_reviewer?.user?.id != this.currentUserId)) {
      this.isPreview = true;
    }
    else {
      this.route.queryParams.subscribe(params => {
        this.isPreview = params['isPreview'];
      });
    }
  }

  getPDFdocument(orderDetail: OrderResponse) {
    if (orderDetail?.reviews[0].review_document.length > 0) {
      this.documents = orderDetail?.reviews[0].review_document;
      const filteredDocuments = this.documents.filter((document: { name: string; doc_type: string; })=> {
        const extension = config.checkFileExtension.exec(document.name);
        return document.doc_type === 'Appraisal' && extension && (extension[1]).toLowerCase() === 'pdf';
    });

      if (filteredDocuments.length > 0) {
        this.downloadFile(filteredDocuments[filteredDocuments.length - 1].name).subscribe(url => {
          this.pdfUrl = url;
        });
      }
    }
  }

  extractFindingsCount(orderDetail: OrderResponse) {
    this.totalfindings = orderDetail?.reviews[0]?.review_findings ? orderDetail?.reviews[0]?.review_findings.length : 0;
    this.resolvedfindings = orderDetail?.reviews[0]?.review_findings?.filter((finding: any) => !!finding?.comment || finding?.review_finding_status?.name === 'Approved').length;
    this.rejectedfindings = orderDetail?.reviews[0]?.review_findings?.filter((finding: any) => !!finding?.comment && finding?.review_finding_status?.name === 'Rejected').length;
    this.countData['Approved'] = orderDetail?.reviews[0]?.review_findings?.filter((finding: any) => finding?.review_finding_status?.name === 'Approved').length;
    this.countData['Rejected'] = orderDetail?.reviews[0]?.review_findings?.filter((finding: any) => finding?.review_finding_status?.name === 'Rejected').length;
    this.countData['Not Applicable'] = orderDetail?.reviews[0]?.review_findings?.filter((finding: any) => finding?.review_finding_status?.name === 'Not Applicable').length;

  }

  updateResolvedfindingsCount(commentAdded: boolean): void {
    if (commentAdded) {
      this.resolvedfindings++;
    }
  }

  getOrderReviewPDFHeaderData(orderDetail: OrderResponse): void {
    const {
      client: { company_name: clientName, ead_active: eadStatus, ucdp_active: ucdpStatus, address_1: clientAddress },
      client_on_report: { company_name: clientOnReportName, address_1: clientOnReportAddress },
      appraiser_first_name,
      appraiser_last_name,
      reviews,
      product,
      created_on,
      due_date,
    } = orderDetail;

    this.orderReviewHeaderData.client = `${clientName}`;
    this.orderReviewHeaderData.clientAddress = `${clientAddress}`;
    this.orderReviewHeaderData.clientOnReport = `${clientOnReportName}`;
    this.orderReviewHeaderData.clientOnReportAddress = `${clientOnReportAddress}`;
    this.orderReviewHeaderData.isEADactive = eadStatus;
    this.orderReviewHeaderData.isUCDPactive = ucdpStatus;
    if (reviews?.length > 0) {
      this.orderReviewHeaderData.reviewer = this.getReviewerData(reviews);
      this.orderReviewHeaderData.vendor = this.getVendorData(reviews);

      this.orderReviewHeaderData.revisionNo = reviews[0].review_version_number;
      this.orderReviewHeaderData.borrowerName = reviews[0].borrower_name;
      this.orderReviewHeaderData.loanType = reviews[0].loan_type;
      this.orderReviewHeaderData.fhaCaseNo = reviews[0].fha_case_number;
      this.orderReviewHeaderData.purposeType = reviews[0].order_purpose_type;

      this.orderReviewHeaderData.address = this.setAddress(reviews);
      const uspsAddress = this.orderDetail?.reviews[0]?.order_address_verification;
      if (uspsAddress === true) {
        this.addressVerified = USPSVerification.VERIFIED;
      } else if (uspsAddress === false) {
        this.addressVerified = USPSVerification.NOT_VERIFIED
      }
    } else {
      this.orderReviewHeaderData.reviewer = null;
      this.orderReviewHeaderData.vendor = null;
      this.orderReviewHeaderData.revisionNo = 0;
      this.orderReviewHeaderData.address = '';
    }

    this.orderReviewHeaderData.product = product?.name;

    this.orderReviewHeaderData.appraiser = appraiser_first_name && appraiser_last_name
      ? `${appraiser_first_name} ${appraiser_last_name}` : null;

    this.orderReviewHeaderData.createdDate = created_on ?? null;
    this.orderReviewHeaderData.dueDate = due_date ?? null;
  }

  getReviewerData(reviews: any) {
    return reviews[0].assigned_to_reviewer?.user
    ? reviews[0].assigned_to_reviewer.user.last_name ? `${reviews[0].assigned_to_reviewer.user.first_name} ${reviews[0].assigned_to_reviewer.user.last_name}` :
      `${reviews[0].assigned_to_reviewer.user.first_name}` : null;
  }

  getVendorData(reviews: any) {
    return reviews[0].assigned_to_vendor?.user
        ? reviews[0].assigned_to_vendor.user.last_name ? `${reviews[0].assigned_to_vendor.user.first_name} ${reviews[0].assigned_to_vendor.user.last_name}` :
          `${reviews[0].assigned_to_vendor.user.first_name}` : null
  }

  setAddress(reviews: any) {
    return `${reviews[0].address_1} ${reviews[0].address_2 ? reviews[0].address_2 + ',' : ''} ${reviews[0].city}, ${reviews[0].state}, ${reviews[0].zip}`
  }

  downloadFile(fileName: string): Observable<string> {
    return this.orderService.downloadReviewDocumnets(fileName).pipe(
      map((data) => data.url)
    );
  }

  getFilteredFindings(findingsType: string, findings: ReviewFinding[], isAction?: boolean): void {
    this.findingsTabSelection = findingsType;
    this.filteredFindings = findings;
    if (isAction) {
      this.isActionPerformed = isAction
    } else {
      this.isActionPerformed = false;
    }
    if (this.findingsTabSelection === 'All') {
      this.findingsData = this.filteredFindings;
    } else if (this.findingsTabSelection === 'resolved') {
      this.findingsData = this.filteredFindings?.filter((finding: any) => !!finding?.comment || finding?.review_finding_status?.name === 'Approved');
    } else if (this.findingsTabSelection === 'unResolved') {
      this.findingsData = this.filteredFindings?.filter((finding: any) => finding?.comment === null && finding?.review_finding_status?.name !== 'Approved');
    } else {
      this.findingsData = [];
    }
    this.sortFindingsByUpdatedOn(this.findingsData);
  }

  sortFindingsByUpdatedOn(findingsList: ReviewFinding[]) {
    this.findingsData = findingsList.sort((a, b) => {
      const dateA = new Date(a.updated_on);
      const dateB = new Date(b.updated_on);
      return dateB.getTime() - dateA.getTime();
    });
  }

  onFormChange(event: any, finding: any = null, findingClicked = false) {
    this.getHtml(event, findingClicked);
    const formImages = this.orderDetail.reviews[0]?.review_image.filter((orderImage: any) => orderImage.review_form.id === event.id);
    if (formImages.length > 0) {
      const imageObservables = formImages.map((image: any) => this.downloadFile(image.key));
      forkJoin(imageObservables).subscribe((imageUrls: any) => {
        const pdfTitle = this.elementRef.nativeElement.querySelectorAll(`[data-title="page_title"]`);
        if (pdfTitle?.length > 0) {
          pdfTitle[0].innerHTML = formImages[0].review_form.page_title;
        }
        formImages.forEach((image: any, i: number) => {
          const images = this.elementRef.nativeElement.querySelectorAll(`[data-image-id="${image.image_sequence}"]`);
          images[0].innerHTML = `<img src='${imageUrls[i]}' style="width:100%; height: 100%; object-fit: contain;">
                                <span data-scroll-xpath="${image.image_sequence}" class="form-chip">${finding?.review_finding_type ? finding.review_finding_type.name : ''}</span>`;
          const labels = this.elementRef.nativeElement.querySelectorAll(`[data-lable="${image.image_sequence}"]`);
          if (labels?.length > 0) {
            labels[0].innerHTML = image.labels.trim();
          }
        })
        this.getElementType(finding);
      })
    }
  }

  getElementType(finding: any) {
    if (finding?.xpath) {
      const inputElement = this.elementRef.nativeElement.querySelector(`[data-scroll-xpath="${finding.xpath}"]`);
      const inputElementImg = this.elementRef.nativeElement.querySelector(`[data-image-id="${finding.xpath}"]`);
      setTimeout(() => {
        if (inputElement) {
          inputElement.style.display = 'block';
          const spanElement = inputElement.parentElement;
          spanElement!.style.overflow = 'visible';
          this.orderService.scrollToFinding(inputElement, this.containerElement);
        }
        if (inputElementImg) {
          const spanElementImg = inputElementImg.parentElement;
          spanElementImg!.style.overflow = 'visible';
          this.orderService.scrollToFinding(inputElement, this.containerElement);
        }
      }, 3000);
    }
  }

  getFilteredForms(event:any) {
    this.orderReviewForms = event;
  }

  expendOrderInfo() {
    this.expendInfo = !this.expendInfo;
  }

  togglePdfView() {
    this.revisionRequested = !this.revisionRequested;
  }

  draggedFindingPlace(data: any) {
    this.draggedFindings = data;
  }
  
  backToOrderDetail ($event: boolean) {
    if ($event) {
      this.stepForRevision = 0;
      this.goBackToFindingOrReview()
    }
  }

  getRevisionTypes() {
    this.orderService.getRevisionList(this.orderId, this.token).subscribe((response) => {
      this.revisionList = response;
      this.selectedRevision = response && response.length > 0 ? response[0] : [];
    });
  }

  sendRevision(step: number) {
    const params = {
      revision_type_id: this.selectedRevision?.id,
      revision_message: '',
      client_message: '',
      revision_sent_to_appraiser_id: this.orderDetail?.reviews[0]?.assigned_to_vendor?.user?.id,
      revision_sent_by_id: this.currentUserId,
      review_id: this.reviewId,
    };

    let revisionTextAppraiser = this.elementRef.nativeElement.querySelector(
      `[class="sendMessagetoAppraiser"]`,
    );
    if (revisionTextAppraiser) {
      params.revision_message = revisionTextAppraiser.innerHTML;
    }

    if (!this.revisionSendToClient && step === 0) {
      this.submitRevision(params);
      return;
    }

    let revisionTextClient = this.elementRef.nativeElement.querySelector(
      `[class="sendMessagetoClient"]`,
    );
    if (revisionTextClient) {
      params.client_message = revisionTextClient.innerHTML;
    }

    if (this.revisionSendToClient) {
      this.stepForRevision = 1;
    }

    if (this.revisionSendToClient && step === 1) {
      this.submitRevision(params);
    }
  }

  submitRevision(params: any) {
    this.confirmActionRequestForRevision(params)
  }

  confirmActionRequestForRevision(params: any) {
    const dialogRef = this.dialog.open(OrderCompletedModalComponent, {
      panelClass: ['order-complete-modal'],
      maxWidth: '100%',
      autoFocus: false,
      disableClose: true,
      data: {
        heading: 'Request for Revision',
        contentData: "Are you sure you want to perform this action?",
        orderId: this.orderId,
        reviewId: this.reviewId,
        actionHeading: 'Save'
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.orderService.sendRevisionToAppraiserOrClient(params, this.token).then(() => {
          this.stepForRevision = 0;
          this.revisionRequested = false;
          this.orderService.updateOrders(this.orderId, 'REVISION_REQUESTED', '').subscribe((response) => {
            if (response) {
              this.getOrderDetails(true, false);
              this.toastr.info(message.revisionRequested);
            }
          },
            (error) => {
              this.toastr.error(error?.error?.detail && !Array.isArray(error.error.detail) ? error.error.detail : generalErrorMessage);
            });
        });
      }
    });
  }

  goBackToFindingOrReview() {
    if (this.stepForRevision === 0) {
      this.revisionRequested = false;
      this.sortRevisionArray = [];
    }
    if (this.stepForRevision === 1) {
      this.stepForRevision = 0;
    }
  }

  sortedArray(event: any) {
    this.sortRevisionArray = event;
    if (this.sortRevisionArray.length > 0) {
      this.spliceFindingFromList(this.lastSelectedFinding, this.sortRevisionArray);
    }
  }

  findingIdRemove(findingId: any) {  
    this.lastSelectedFinding = findingId?.findingId
  }

  spliceFindingFromList(finding: any, array: any) {
    if (finding) {
      this.sortRevisionArray = array.filter((item: any) => item.id !== finding);
    }
  }

  updateSortedList(event: any) {
    this.sortRevisionArray = event;
  }

  upadteReviewrAssignment(requestBody: any) {
    requestBody.reviewer_uid = this.reviewerCtrl.value?.external_id;
    this.orderService.updatePilotReviewer(requestBody)?.subscribe(
      (response) => {
        if (response) {
          if (this.currentUserRole === 'Admin') {
            this.toastr.success(message.updateReviewerAssignment);
            this.router.navigate(['/orders']);
          } else {
            this.toastr.success(message.updateReviewerAssignment);
            const queryParams = { ...this.route.snapshot.queryParams, isPreview: true };
            this.router.navigate([], {relativeTo: this.route,queryParams: queryParams, queryParamsHandling: 'merge'});
            this.getOrderDetails(false, true);
          }
        }
      },
      (error) => {
        this.reviewerCtrl.setValue(this.orderDetail?.reviews[0]?.assigned_to_reviewer?.user);
        this.filterOptions(this.orderDetail?.reviews[0]?.assigned_to_reviewer?.user);
        this.toastr.error(error?.error?.detail && !Array.isArray(error.error.detail) ? error?.error?.detail : generalErrorMessage);
      }
    );
  }

  escalate(requestBody: any) {
    const orderIds = [this.orderId];
    this.orderService.escalateReview(requestBody, orderIds)?.subscribe(
      (response) => {
        if (response) {
          this.toastr.success('Order has been escalated.');
          this.router.navigate(['/orders']);
        }
      },
      (error) => {
        this.toastr.error(error?.error?.detail && !Array.isArray(error.error.detail) ? error?.error?.detail : generalErrorMessage);
      }
    );
  }

  openReviwerAssignmentDialog(title: string, button: string, secondaryBtn: string) {
    const dialogRef = this.dialog.open(OrderReviewerAssignmentComponent, {
      panelClass: ['documents-modal'],
      maxWidth: '100%',
      autoFocus: false,
      disableClose: true,
      data: {
        orderNumber: this.orderNo,
        reviewId: this.reviewId,
        title,
        button,
        secondaryBtn
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        title === 'Escalate To' ? this.escalate(result) : this.upadteReviewrAssignment(result);
      }
    });
  }

  getReviewers(): void {
    this.orderService.getPiolotUsers()?.then((users: any) => {
      this.adminList = users.Admins;
      this.managerList = users.Managers;
      this.reviewerList = users.Reviewers;

      this.filteredAdmins = this.adminList;
      this.filteredManagers = this.managerList;
      this.filteredReviewers = this.reviewerList;
      this.assignedUsersList = [...this.adminList, ...this.managerList, ...this.reviewerList];
    });
  }

  getTitle(selectedUser: any) {
    const result = this.assignedUsersList.find((user: any) => user.id === selectedUser?.id);
    if (result) {
      const lastName = result.last_name ? result.last_name : '';
      return `${result.first_name} ${lastName}`;
    }
    return '';
  }

  filterOptions(value: any) {
    this.showInitials = false;
    if (value) {
       const isObject  = value?.first_name;
       if (isObject) {
        this.filteredAdmins =  this.adminList.filter((user:any) => user.id === value?.id);
        this.filteredManagers =  this.managerList.filter((user:any) => user.id === value?.id);
        this.filteredReviewers =  this.reviewerList.filter((user:any) => user.id === value?.id);
       } else {
        const filterValue = value.toLowerCase();
        this.filteredAdmins = this.adminList.filter((user: any) =>
        user.first_name.toLowerCase().includes(filterValue) ||
        user?.last_name?.toLowerCase().includes(filterValue)
      );
        this.filteredManagers = this.managerList.filter((user: any) =>
        user.first_name.toLowerCase().includes(filterValue) ||
        user?.last_name?.toLowerCase().includes(filterValue)
      );
        this.filteredReviewers = this.reviewerList.filter((user: any) =>
        user.first_name.toLowerCase().includes(filterValue) ||
        user?.last_name?.toLowerCase().includes(filterValue)
      );}
    } else {
      this.filteredAdmins = this.adminList;
      this.filteredManagers = this.managerList;
      this.filteredReviewers = this.reviewerList;
    }

  }

  closeDropdownForReviewer() {
    this.isDropdownOpen = false;
    if (!this.reviewerCtrl.value) {
      this.reviewerCtrl.setValue(this.orderDetail?.reviews[0]?.assigned_to_reviewer?.user);
      this.filterOptions(this.orderDetail?.reviews[0]?.assigned_to_reviewer?.user);
      this.showInitials = this.orderDetail?.reviews[0]?.assigned_to_reviewer?.user?.public_image ? false : true;
    }
  }

  clearSelection() {
    this.reviewerCtrl.reset();
  }

  onFocus() {
    if (!this.isPreview && this.userConfig.checkPermissions(this.permission.REVIEWER_ASSIGNMENT)) {
      this.isDropdownOpen = true;
    }
  }

  loadImage(key: string) {
    // Load image if it's not cached
    if (key && !this.imageCache[key]) {
      // Call the image download service
      this.sharedService.downloadProfileImage(key).subscribe((imageUrl) => {
        this.imageCache[key] = imageUrl.url; // Cache the loaded image
      });
    }
  }

  changePdfDisplayState() {
    this.togglePdfDisplayState();
  }

}
